.xtPScG_bodyModal {
  width: 100%;
  margin-top: 30px;
}

.xtPScG_GenericModal {
  text-align: left;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 36px;
  display: flex;
}

.xtPScG_GenericModalHeader {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.xtPScG_GenericModalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}

.xtPScG_GenericModalSubtitle {
  color: #7f7f7f;
  text-align: left;
  width: 100%;
  margin-top: 6px;
  font-size: 14px;
}

.xtPScG_GenericModalHeaderCloseIcon, .xtPScG_GenericModalHeaderCloseIcon img {
  width: 12px;
  height: 12px;
}

.xtPScG_GenericModalFooter {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=Reports.b617d85e.css.map */
